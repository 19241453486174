<template>
  <div class="tools">
    <uikit-block class="block">
      <uikit-tabs
        class="tabs"
        :tab-list="tabList"
      >
        <template
          v-for="(tab, index) in tabContent"
          #[getTabPanel(index)]
          :key="index"
        >
          <ToolsContent
            :title="tab.title"
            :image="tab.image"
            :list="tab.list"
          />
        </template>
      </uikit-tabs>
    </uikit-block>
  </div>
</template>

<script>
import { UikitBlock, UikitTabs } from '@hz/ui-kit'

export default {
  components: {
    UikitBlock,
    UikitTabs,
  },
  data() {
    return {
      tabList: [
        {
          icon: { img: '/imgs/pages/gestion/tools-bail.webp' },
          title: 'Bail de location',
        },
        {
          icon: { img: '/imgs/pages/gestion/tools-auto.webp' },
          title: 'Automatisation',
        },
        {
          icon: { img: '/imgs/pages/gestion/tools-synchro.webp' },
          title: 'Synchronisation bancaire',
        },
        {
          icon: { img: '/imgs/pages/gestion/tools-perf.webp' },
          title: 'Performances immobilières',
        },
        {
          icon: { img: '/imgs/pages/gestion/tools-document.webp' },
          title: 'Modèles de documents',
        },
        {
          icon: { img: '/imgs/pages/gestion/tools-declaration.webp' },
          title: 'Déclaration de revenus fonciers',
        },
      ],
      tabContent: [
        {
          title: 'Gérez votre <strong>bail de location</strong> simplement',
          list: [
            'Créez vos contrats de location <strong>100% en ligne</strong>',
            'Choisissez votre contrat : bail location appartement ou maison, bail location meublé ou non meublé, bail location mobilité, bail colocation...',
            "Profitez d'un <strong>bail pré-rempli</strong> à imprimer ou à envoyer pour une <strong>signature électronique</strong>",
          ],
          image: '/imgs/pages/gestion/tools-tab-bail.png',
        },
        {
          title: '<strong>Automatisez vos tâches</strong> de gestion locative',
          list: [
            'Envoi automatique de <strong>quittances de loyer</strong> et relances',
            "<strong>Alertes d'impayés de loyer</strong> et de retards de paiement",
          ],
          image: '/imgs/pages/gestion/tools-tab-auto-v2.png',
        },
        {
          title: 'Synchronisez <strong>vos comptes bancaires</strong>',
          list: [
            'Connectez vos comptes bancaires en quelques clics',
            '<strong>Soyez notifié</strong> de tous les paiements (loyers, CAF...)',
            'Récupérez les transactions bancaires liées à la gestion locative de vos biens',
          ],
          image: '/imgs/pages/gestion/tools-tab-synchro-v2.png',
        },
        {
          title:
            'Pilotez la <strong>performance de votre patrimoine immobilier</strong> pour prendre les bonnes décisions',
          list: [
            "Suivez sur un même tableau de bord vos <strong>indicateurs clés</strong> : rendement net, cashlflow positif ou négatif, actifs bruts et nets, coût de l’impôt, taux d'occupation…",
            'Estimez votre <strong>plus-value nette</strong> pour décider de vendre… ou ne pas vendre',
            '<strong>Comparez votre rendement</strong> avec celui d’autres investissements (Livret A, SCPI, assurance-vie…)',
          ],
          image: '/imgs/pages/gestion/tools-tab-perf-v2.png',
        },
        {
          title: 'Accédez à nos <strong>modèles et pdf de documents</strong>',
          list: [
            "Etat des lieux d'entrée et de sortie",
            'Inventaire des meubles',
            'Acte de caution solidaire',
            'Avenant au bail',
            "Et bien d'autres !",
          ],
          image: '/imgs/pages/gestion/tools-tab-document-v2.png',
        },
        {
          title: 'Déclarez <strong>vos revenus fonciers en un éclair</strong>',
          list: [
            'Calcul et <strong>régularisation des charges</strong> locatives',
            'Calcul de <strong>révision annuel de loyer</strong> / indexation du loyer',
            "Pré remplissage de votre <strong>déclaration d'impôts 2044</strong>",
          ],
          image: '/imgs/pages/gestion/tools-tab-declaration-v2.png',
        },
      ],
    }
  },
  methods: {
    getTabPanel(index) {
      return `tabPanel-${index + 1}`
    },
  },
}
</script>

<style lang="less" scoped>
.tools {
  .app-padding();

  padding-bottom: 50px;

  .block {
    margin: -40px auto 0;
    max-width: 1700px;
  }

  @media @bp-xxlg {
    .tabs {
      width: 85%;
    }
  }
}
</style>
