<template>
  <div class="tools-content">
    <div class="tools-text">
      <h3
        class="title"
        v-html="title"
      />
      <ul
        v-if="list"
        class="list"
      >
        <li
          v-for="(item, index) in list"
          :key="index"
          class="item"
          v-html="item"
        />
      </ul>
    </div>
    <div class="tools-image">
      <img
        v-if="image"
        :src="image"
        class="content-image"
        :alt="title"
        loading="lazy"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
    },
    image: {
      type: String,
    },
  },
}
</script>

<style lang="less" scoped>
.tools-content {
  .tools-text,
  .tools-image {
    width: 100%;
  }

  @media @bp-desktop {
    display: flex;
    align-items: center;

    .tools-text {
      width: 60%;
    }

    .tools-image {
      width: 40%;
    }
  }

  .title {
    color: #272727;
    font-weight: 800;
    font-size: 26px;
  }

  ul.list {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 20px 20px;

    list-style-image: url('~/assets/imgs/ticks.svg');

    & > li {
      margin-top: 16px;
      padding-left: 6px;
    }
  }

  .content-image {
    float: right;
    max-width: 488px;
    width: 100%;
  }
}

.title :deep(strong) {
  color: var(--ds-color-primary-100);
}
</style>
